const Logo = (props: any) => {

    const { className, color } = props

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1212.31 335.07">
            <path className={className} fill={color} d="M159.94,102.14a18.25,18.25,0,0,1,25.77,0l36.17,36.17,14.42-14.42a11.24,11.24,0,0,0,0-15.88L178,49.68a11.22,11.22,0,0,0-15.88,0L59,152.81a11.23,11.23,0,0,0,0,15.89l17.22,17.22Z"/>
            <rect className={className} fill={color} x="97.95" y="111.6" width="104.95" height="168.32" rx="11.23" transform="translate(182.48 -49.03) rotate(45)"/>
            <path className={className} fill={color} d="M326.5,228.55H288.43V132H326.5q20.69,0,34,12.14a41.31,41.31,0,0,1,10.73,15.63,60.15,60.15,0,0,1,0,41.16,41.26,41.26,0,0,1-10.73,15.64,43.2,43.2,0,0,1-15.08,9A56.77,56.77,0,0,1,326.5,228.55Zm-19.6-16.36h19.66A47.38,47.38,0,0,0,337,211a23.06,23.06,0,0,0,9.57-4.69,25.82,25.82,0,0,0,7.06-9.69q2.76-6.21,2.77-16.23t-2.77-16.3a24.81,24.81,0,0,0-7.13-9.69,23.77,23.77,0,0,0-9.63-4.62,46.94,46.94,0,0,0-10.29-1.19H306.9Z"/>
            <path className={className} fill={color} d="M406.51,148.34v24.14h46.7v16.76h-46.7v22.69h52.64v16.62H388V132h71.11v16.36Z"/>
            <path className={className} fill={color} d="M563.9,204.8a46.39,46.39,0,0,1-7.78,10.49,51.43,51.43,0,0,1-10.22,8.05,50,50,0,0,1-25.66,7.06,48.12,48.12,0,0,1-19.4-4,50.58,50.58,0,0,1-26.65-26.65,49.48,49.48,0,0,1,0-38.79,50.67,50.67,0,0,1,26.65-26.65,48.28,48.28,0,0,1,19.4-3.95,49.52,49.52,0,0,1,13.52,1.84,50.38,50.38,0,0,1,12.14,5.21,52.17,52.17,0,0,1,10.22,8.05,48.21,48.21,0,0,1,7.78,10.36l-17.28,7a37.33,37.33,0,0,0-5-5.94,36.38,36.38,0,0,0-6.26-4.88,32.55,32.55,0,0,0-7.26-3.3,26.59,26.59,0,0,0-7.91-1.18,28.64,28.64,0,0,0-12.34,2.7,32.66,32.66,0,0,0-10,7.19,34.49,34.49,0,0,0-6.73,10.49,32.38,32.38,0,0,0,0,25,34.64,34.64,0,0,0,6.73,10.42,32.5,32.5,0,0,0,10,7.19,28.5,28.5,0,0,0,12.34,2.71,26.6,26.6,0,0,0,7.91-1.19,31.67,31.67,0,0,0,7.26-3.3,33.6,33.6,0,0,0,6.26-4.95,39.61,39.61,0,0,0,5-6Z"/>
            <path className={className} fill={color} d="M681.71,273l-85.09-88.37v43.93H578.15V132h18.47v49.08L635,132.11h22.7L618,182l88.12,91Z"/>
            <path className={className} fill={color} d="M752.37,148.34H716.8l-1,108.21-17.47-20V148.34H663.62l12-16.36h66.75Z"/>
            <path className={className} fill={color} d="M1011.81,228.55H973.74V132h38.07q20.67,0,34,12.14a41.31,41.31,0,0,1,10.73,15.63,60.15,60.15,0,0,1,0,41.16,41.26,41.26,0,0,1-10.73,15.64,43.2,43.2,0,0,1-15.08,9A56.76,56.76,0,0,1,1011.81,228.55Zm-19.6-16.36h19.65a47.53,47.53,0,0,0,10.43-1.18,23.08,23.08,0,0,0,9.56-4.69,25.82,25.82,0,0,0,7.06-9.69q2.77-6.21,2.77-16.23t-2.77-16.3a24.81,24.81,0,0,0-7.13-9.69,23.77,23.77,0,0,0-9.63-4.62,46.87,46.87,0,0,0-10.29-1.19H992.21Z"/>
            <path className={className} fill={color} d="M1089.81,148.34v24.14h46.71v16.76h-46.71v22.69h52.64v16.62h-71.11V132h71.11v16.36Z"/>
            <polygon className={className} fill={color} points="777.46 228.51 777.52 181.45 777.5 181.45 777.5 165.66 759.03 165.66 759.03 228.55 777.5 228.55 777.5 228.51 777.46 228.51"/>
            <path className={className} fill={color} d="M816.13,196.1a30.71,30.71,0,0,0,14.6-11.74,33.52,33.52,0,0,0,5.48-19.13,35.88,35.88,0,0,0-2.44-13.4A31.44,31.44,0,0,0,827,141.28a30.18,30.18,0,0,0-10.5-6.86A36.34,36.34,0,0,0,802.91,132H759.59l17.92,33.52V148.34h25.38a14.27,14.27,0,0,1,7.75,1.85,13.56,13.56,0,0,1,4.47,4.48,16.52,16.52,0,0,1,2.11,5.54,28.78,28.78,0,0,1,.52,5,17.07,17.07,0,0,1-1.38,7.12,16.56,16.56,0,0,1-3.48,5.08,13.49,13.49,0,0,1-4.8,3,15.05,15.05,0,0,1-5.19,1h-15.2l30.1,46.65.35.45h22Q828.31,212.2,816.13,196.1Z"/>
            <path className={className} fill={color} d="M872.44,228.53l45.92-96.44h1l45.78,96.44H944.87l-26-55.94-26,55.94Z"/>
            <polygon className={className} fill={color} points="862.35 228.39 898.62 151.47 889.41 132.09 888.36 132.09 842.44 228.53 862.89 228.53 862.96 228.39 862.35 228.39"/>
        </svg>
    )
}

export default Logo